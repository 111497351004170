@use '~@strategies/tokens/color';

@import 'core';
@import 'vendor';

.logo {
    color: $color_brand;
    font-family: $font_family-secondary;
    font-size: $font_size-larger;

    span {
        font-family: $font_family-primary;
        font-weight: 100;
    }
}

.App {
    .Section {
        padding: $dims_padding-medium;

        @include respond-to(tablet) {
            padding: $dims_padding-medium $dims_padding-large;
        }

        &:nth-of-type(2n+1) {
            background: $color_bg-light-grey;
        }

        h3 {
            margin: 0;

            color: $color_black;
            font-size: $font_size-medium;
            font-weight: 400;
        }

        h2 {
            margin: $dims_padding-small 0 $dims_padding-medium;

            color: $color_brand;
            font-family: $font_family-secondary;
            font-size: $font_size-largest * .625;
            font-weight: 700;
        }

        p {
            line-height: 1.5em;
            font-size: $font_size-medium;
        }
    }

    button, .button {
        &.with-arrow {
            &:after {
                content: url(./assets/arrow-white.svg);
                display: inline-block;
                vertical-align: middle;
                top: 1px;
                margin-left: $dims_padding-small * 1.5;
            }

            &.blue:after {
                content: url(./assets/arrow-brand.svg);
            }
        }
    }

    footer {
        width: 100%;
        padding: $dims_padding-small $dims_padding-medium;

        background: $color_black;

        @include respond-to(tablet) {
            padding: $dims_padding-small $dims_padding-large;
        }

        .logo {
            display: inline-flex;
            align-items: center;
            gap: $dims_padding-small;

            img {
                height: $font_size-medium;

                @include respond-to(tablet) {
                    height: $font_size-larger;
                }
            }

            h3 {
                margin: auto;
                font-size: $font_size-medium;
                font-weight: 400;
                color: color.$white;
            }
        }

        button {
            position: absolute;
            right: $dims_padding-medium;
            top: 50%;
            transform: translateY(-50%);

            width: 2rem;
            height: 2rem;
            margin: auto;

            @include respond-to(tablet) {
                width: 3.125rem;
                height: 3.125rem;
            }

            background: $color_bg-light-grey;
            border: none;
            border-radius: 50%;
            box-shadow: $box_shadow;
            cursor: pointer;

            img {
                width: 70%;
                top: 2px;
                transform: rotate(-90deg);
                transition: top .2s;
            }

            &:hover {
                img {
                    top: -1px;
                }
            }
        }
    }
}

@import 'components/Events/Events';
@import 'components/Contact/Contact';
@import 'components/ContactForm/ContactForm';
@import 'components/Process/Process';
@import 'components/Resources/Resources';
@import 'components/CampusHistory/CampusHistory';
@import 'components/CampusMap/CampusMap';
@import 'components/Welcome/Welcome';
@import 'components/Landing/Landing';
