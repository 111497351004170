@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';


.Landing {

    .Anchor {
        top: 100px;
    }

    &.Section {
        display: flex;
        flex-direction: column;
        align-items: center;

        min-height: 100vh;
        padding: $dims_padding-large * 1.65 0 0;

        background-image: url(../../assets/banner.jpg) !important;
        background-size: cover !important;
        background-position: center center !important;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: 100%;

            background: linear-gradient(to right, #{rgba(#FBFAF4, 1)}, #{rgba(#FBFAF4, .25)});
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            width: 100%;
            height: 100%;
            min-height: 0;
            padding: 0 $dims_padding-medium;
            margin: auto 0;

            @include respond-to(tablet) {
                padding: 0 $dims_padding-large;
            }
        }

        h2, h3 {
            color: $color_black;
        }

        h2 {
            flex: 1;
            width: 80%;
            margin: $dims_padding-medium auto;
            margin-left: 0;

            font-family: $font_family-primary;
            font-size: $font_size-largest * .6;
            font-weight: 600;
            line-height: 1.2em;

            @include respond-to(tablet) {
                font-size: $font_size-largest;
            }
        }

        h3 {
            max-width: 20em;
            margin: 0 auto 0 0;
            text-align: left;
            font-size: $font_size-medium;
        }

        .button {
            display: none;
            margin: 0 auto 0 0;

            margin: $dims_padding-medium 0;
            margin-right: auto;
            padding: $dims_padding-small $dims_padding-small * 2;

            color: $color_font-light;
            font-size: $font_size-medium;

            background: $color_brand--secondary;
            border-radius: $box_radius-large;
            transition: background .2s;

            &:hover {
                background: darken($color_brand--secondary, 5);
            }
        }
    }

    &-wrap {
        footer {
            display: none;
            position: absolute;
            bottom: 2em;

            margin: auto;

            font-size: $font_size-medium;
            text-align: center;

            background: none;
            cursor: pointer;
            opacity: 1;
            pointer-events: all;
            transition-delay: 1s;
            transition: opacity animation.$speed, transform animation.$speed;
            transform: translateY(0em);

            @include respond-to(tablet) {
                //display: block;
            }

            &.hidden {
                opacity: 0;
                transform: translateY(-1em);
                transition-delay: 0s;
                pointer-events: none;
            }

            p {
                color: color.$white;
            }

            &:hover {
                svg {
                    transform: translateY(.1em);
                }
            }

            svg {
                $size: $font_size-large * 3;

                width: $size;
                height: $size;
                margin: auto;
                color: $color_brand;
                transform: translateY(0em);
                transition: transform animation.$speed * .5;
            }
        }
    }
}
