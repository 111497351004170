.Process {
    ul {
        margin-top: $dims_padding-medium;

        @include respond-to(tablet) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $dims_padding-small;
        }
    }

    li {
        padding: $dims_padding-small * 2;

        text-align: center;

        border-radius: $box_radius-medium;
        background: $color_bg-light-grey;

        + li {
            margin-top: $dims_padding-small;

            @include respond-to(tablet) {
                margin-top: 0;
            }
        }

        p {
            font-size: $font-size-small !important;
            text-align: left;
        }
    }

    h4, h5, h6 {
        margin: $dims_padding-small auto;
    }

    h4 {
        margin-top: 0;
        font-size: 1.2em;
    }

    h6 {
        font-size: $font_size-small;
        font-weight: 400;
    }

    h5 {
        color: $color_brand;
        font-size: $font_size-large;
        line-height: 1.5em;
        font-weight: 700;
    }
}
