.CampusMap {

    text-align: center;

    h2, h3 {
        text-align: left;
    }

    img {
        max-width: 100%;
        max-height: 80vh;
        margin: auto;
    }

}
