.CampusHistory {
    .content {
        text-align: center;

        h3, h2, p, li {
            text-align: left;
        }

        .bold {
            font-weight: 800;
        }

        a, a:visited {
            color: $color_brand;
            text-decoration: underline;
        }
    }

    video {
        max-width: 100%;
        max-height: 80vh;
        margin: $dims_padding-medium auto;
    }

}
