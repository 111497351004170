@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';

.Welcome {

    .row {
        @include respond-to(tablet) {
            display: flex;
            gap: layout.$padding--large;
            align-content: center;
            margin: $dims_padding-large 0;
        }

        .col {
            flex: 1 1 50%;
            margin: auto;
        }
    }

    .image-wrap {
        text-align: center;
        border-radius: $box_radius-medium;
        overflow: hidden;

        margin: $dims_padding-medium 0;

        @include respond-to(tablet) {
            margin: 0;
        }

        img {
            position: absolute;
            min-height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            border-radius: $box_radius-medium;
            margin: auto;
        }
    }

}
